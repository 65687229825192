$(".burger-menu").on("click", function () {
    $(this).toggleClass("active")
    $(".menu-container").toggleClass("active")
    $("body").toggleClass("no-scroll")
})

$(".menu-container").on("click", function (event) {
    event.stopPropagation()
    if (event.target === $(this)[0]) {
        $(this).removeClass("active")
        $("body").removeClass("no-scroll")
    }

})